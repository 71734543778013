import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { Link, animateScroll as scroll } from 'react-scroll';
import './Home.css';
import './intro.css';
import './roadmap.css';
import './collection.css';
import './faq.css'

function Home() {
  const [scrolling, setScrolling] = useState(false);

  const [artIndex, setArtIndex] = useState(0);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const changeArt = (direction) => {
    if (direction === "next") {
      setArtIndex((prevIndex) => (prevIndex + 1) % 9);
    } else {
      setArtIndex((prevIndex) => (prevIndex - 1 + 9) % 9);
    }
  };


  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    if (window.innerWidth >= 1024) {
      window.addEventListener("scroll", onScroll);
    }
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop({ duration: 1000, smooth: 'easeInOutQuart' });
  };

  function WhiteLine() {
    return (
      <div className="white-line">
        <div className="white-line-inner"></div>
      </div>
    );
  }

  function HamburgerIcon({ onClick }) {
    return (
      <div className="hamburger-icon" onClick={onClick}>
        <div className="hamburger-line"></div>
        <div className="hamburger-line"></div>
        <div className="hamburger-line"></div>
      </div>
    );
  }

  function FAQItem({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div>
        <div
          className="faq-item"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          <div className="faq-question" style={{ borderColor: "black" }}>
            {question}
          </div>
          <div className="faq-symbol" style={{ color: "black" }}>
            {isOpen ? "-" : "+"}
          </div>
        </div>
        <FAQAnswer isOpen={isOpen}>{answer}</FAQAnswer>
      </div>
    );
  }


  function FAQAnswer({ children, isOpen }) {
    const contentRef = useRef();
    const animationControls = useAnimation();

    useEffect(() => {
      if (isOpen) {
        animationControls.start({ opacity: 1, height: contentRef.current.scrollHeight + 50 });
      } else {
        animationControls.start({ opacity: 0, height: 0 });
      }
    }, [isOpen, animationControls]);

    return (
      <motion.div
        ref={contentRef}
        className={`faq-answer ${isOpen ? "faq-answer-open" : ""}`}
        initial={{ opacity: 0, height: 0 }}
        animate={animationControls}
        transition={{ duration: 0.1, ease: 'easeInOut' }}
      >
        <div className="faq-answer-content">{children}</div>
      </motion.div>
    );
  }


  function RoadmapBox({ children }) {
    return (
      <div className="roadmap-box">
        <div className="roadmap-box-content">{children}</div>
      </div>
    );
  }

  function CollectionBox({ children }) {
    return (
      <div className='collection-box'>
        <div className='collection-box-content'>{children}</div>
      </div>
    )
  }

  function RoadmapTitle({ title }) {
    return (
      <h2 className="roadmap-title-inter">
        {title}
      </h2>
    );
  }


  return (

    <motion.div
      className="all-content"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <header className={`main-header${scrolling ? ' scrolling' : ''}`}>
        <img src="/logo.png" alt="Logo" className="logo header-child" onClick={scrollToTop} />
        <HamburgerIcon onClick={toggleMobileMenu} />
        <nav>
          <Link
            to="second-background"
            className="nav-link"
            spy={true}
            smooth={true}
            duration={1000}
            offset={-60}
            onClick={scrollToTop}
          >
            Roadmap
          </Link>
          <Link
            to="third-background"
            className="nav-link"
            spy={true}
            smooth={true}
            duration={1000}
            offset={-60}
            onClick={scrollToTop}
          >
            Collection
          </Link>
          <RouterLink to="/roulette" className="nav-link">
            Roulette
          </RouterLink>
          <Link
            to="fourth-background"
            className="nav-link"
            spy={true}
            smooth={true}
            duration={1000}
            offset={-60}
          >
            FAQ
          </Link>
        </nav>
        <div className="social-icons header-child">
          <a href="https://twitter.com/PixellCats" target="_blank" rel="noopener">
            <img src="/twitter.png" alt="Twitter" className="social-icon" />
          </a>
          <RouterLink to="https://discord.gg/Mbtd3jU8jF" target="_blank" rel="noopener">
            <img src="/discord.png" alt="Discord" className="social-icon" />
          </RouterLink>
        </div>
      </header>
      <div className={`mobile-menu${mobileMenuOpen ? ' open' : ''}`}>
        <div className="close-menu" onClick={toggleMobileMenu}>
          &times;
        </div>
        <Link
          to="second-background"
          className="nav-link"
          spy={true}
          smooth={true}
          duration={1000}
          offset={-60}
          onClick={toggleMobileMenu}
        >
          Roadmap
        </Link>
        <Link
          to="third-background"
          className="nav-link"
          spy={true}
          smooth={true}
          duration={1000}
          offset={-60}
          onClick={toggleMobileMenu}
        >
          Collection
        </Link>
        <RouterLink to="/roulette" className="nav-link" onClick={toggleMobileMenu}>
          Roulette
        </RouterLink>
        <Link
          to="fourth-background"
          className="nav-link"
          spy={true}
          smooth={true}
          duration={1000}
          offset={-60}
          onClick={toggleMobileMenu}
        >
          FAQ
        </Link>
      </div>
      <div className="first-background">
        <div className="title-container">
          <h1 className="title">Pixel Cats</h1>
          <img src="/logo.png" alt="Logo" className="title-logo" />
        </div>
      </div>

      <WhiteLine />
      <div id="second-background" className="second-background">
        <div className="roadmap-container">
          <h2 className="roadmap-title">Roadmap</h2>
          <RoadmapBox>
            <RoadmapTitle title="Phase 1"></RoadmapTitle>
            <div class="box-text-roadmap">
              <p>• Create a roulette wheel with all traits.</p>
              <br></br>
              <p>• Opening of spots for whitelist and OG for Pixel Cats Collection.</p>
              <br></br>
              <p>• Create a strong community on Solana.</p>
            </div>
          </RoadmapBox>
          <RoadmapBox>
            <RoadmapTitle title="Phase 2"></RoadmapTitle>
            <div class="box-text-roadmap">
              <p>• Initial payment with amount collected during mint and with the percentage of the collection royalties.</p>
              <br></br>
              <p>• First drawing on the platform with part of the value collected during minting to celebrate the launch.</p>
            </div>
          </RoadmapBox>
          <RoadmapBox>
            <RoadmapTitle title="Phase 3"></RoadmapTitle>
            <div class="box-text-roadmap">
              <p>• Allocation of 80% of royalties to the roulette liquidity pool and 20% of payment to team.</p>
              <p>• Make two weekly drawings on predefined dates on the PixelCats platform.</p>
              <p>• Exclusive opportunities to participate in future collaborations and giveaways of Pixel Cats collection holders.</p>
            </div>
          </RoadmapBox>
        </div>
      </div>
      <WhiteLine />
      <div id="third-background" className="">
        <div className="collection-container">
          <div className="collection-title-box">
            <h2 className="collection-title">Collection</h2>
          </div>
          <CollectionBox>
            <div className='collection-text'>
              <p>
                Pixel Cats are more than just collectible tokens, they are a reflection of the passion and creativity that drives our community. With each new member, our network becomes stronger and more diverse, allowing our artists to create new works and our collectors to discover new ways to express themselves. We have dedicated time and effort to creating the Pixel Cats collection, which captures the essence of these adorable felines in a pixelated art style. Our goal is to motivate and bring joy to our community by delivering a collection that they will love and enjoy. We hope that our Pixel Cats will make them feel happy, satisfied, and inspired to keep exploring and building together.              </p>
            </div>
          </CollectionBox>
        </div>
        <div className="artwork-container">
          {Array.from({ length: 9 }, (_, i) => (
            <img
              key={i}
              src={`/cat_0${i + 1}.jpg`}
              alt={`Cat Art ${i + 1}`}
              className="artwork"
            />
          ))}
        </div>
        <div className="art-title-box">
          <h2 className="art-title">The Gallery</h2>
        </div>
        <div className="new-box-container">
          <div className="new-box">
            <img
              src={`/cat_0${artIndex + 1}.jpg`}
              alt={`Cat Art ${artIndex + 1}`}
            />
          </div>
          <div className="arrow-buttons">
            <div className="arrow-button" onClick={() => changeArt("previous")}>
              PREVIOUS
            </div>
            <div className="arrow-button-line" onClick={() => changeArt("previous")}>
              |
            </div>
            <div className="arrow-button" onClick={() => changeArt("next")}>
              NEXT
            </div>
          </div>
        </div>
      </div>
      <WhiteLine />
      <div id="fourth-background" className="footer">
        <div className="faq-container">
          <div className="faq-title-box">
            <h2 className="faq-title">FAQ</h2>
          </div>
          <FAQItem question="Who is the team behind Pixel Cats ?" answer="
          The team behind it is made up of people with experience in Web3,
           builders who decided to combine their skills to make this collection." symbol="+" />
          <FAQItem question=" What will be the functionality of the roulette ?" answer="
          The roulette wheel will determine which traits will receive the airdrop." symbol="+" />
          <FAQItem question="Where i can check the last announcements ?" answer="
          Announcements will be posted on the Pixel Cats discord first, and will be posted on twitter shortly there after, so stay tuned to our discord for the most updated announcements." symbol="+" />
          <FAQItem question="How will the draw work ?" answer="
          The draw will happen live on the roulette, users will be able to enter and watch the draw, we will spin the roulette  so that the trait is chosen. 
          We find it interesting that users see the draw happen live to avoid fraud." symbol="+" />
        </div>
        <div className="footer-logos">
          <img src="/logo.png" alt="Logo" className="logo-footer" onClick={scrollToTop} />
          <div className="social-icons-footer-child">
            <a href="https://twitter.com/PixellCats" target="_blank" rel="noopener">
              <img src="/twitter.png" alt="Twitter" className="social-icon" />
            </a>
            <RouterLink to="https://discord.gg/Mbtd3jU8jF" target="_blank" rel="noopener">
              <img src="/discord.png" alt="Discord" className="social-icon" />
            </RouterLink>
          </div>
          <div className="footer-text-owner">
            <p>Developed by Pixel Cats 2023 © </p>
          </div>
        </div>
      </div>
    </motion.div >
  );
}

export default Home;

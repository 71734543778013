import React from 'react';
import { motion } from 'framer-motion';
import './Roulette.css';
import { useNavigate } from "react-router-dom";

const bounceAnimation = {
  initial: { scale: 1, translateY: 0 },
  animate: { scale: [1, 1.2, 1], translateY: [0, -30, 0] },
  transition: (index) => ({
    duration: 0.8,
    repeat: Infinity,
    repeatType: "loop",
    repeatDelay: (index + 1) * 0.8 * 0.5, 
  }),
};

function Roulette() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/home");
  };

  return (
    <motion.div
      className="roulette-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <img src="/clock.gif" alt="Clock" className="clock-gif" />
      <div className="coming-soon">
        {Array.from("COMING SOON ...").map((char, index) => (
          <motion.span
            key={index}
            className="char"
            initial={bounceAnimation.initial}
            animate={bounceAnimation.animate}
            transition={bounceAnimation.transition(index)} 
          >
            {char}
          </motion.span>
        ))}
      </div>
      <button className="back-button" onClick={handleBack}>Back</button>
    </motion.div>
  );
}

export default Roulette;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import logo_main from "./logo.png";
import "./Main.css";

const Main = () => {
  const [message, setMessage] = useState("");
  const [showEnterButton, setShowEnterButton] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const fullMessage = "Welcome to Pixel Cats";
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (message.length < fullMessage.length) {
        setMessage(fullMessage.slice(0, message.length + 1));
      } else {
        setShowLogo(true);
        setTimeout(() => {
          setShowEnterButton(true);
        }, 500);
      }
    }, 150);

    return () => clearTimeout(timer);
  }, [message]);

  const handleClick = () => {
    navigate("/home");
  };

  const logoVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  return (
    <motion.div
      className="all"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="bg-image"></div>
      <div className="bg-overlay"></div>
      <div className="container">
        <div className="message-container">
          <h1 className="welcome-message">{message}</h1>
          {showLogo && (
            <motion.div
              className="logo-container"
              variants={logoVariants}
              initial="hidden"
              animate="visible"
            >
              <img src={logo_main} alt="Pixel Cats logo" className="logo-main" />
            </motion.div>
          )}
        </div>
        {showEnterButton && (
          <button className="enter-button" onClick={handleClick}>
            Enter
          </button>
        )}
      </div>
    </motion.div>
  );
};
export default Main;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./Main";
import Roulette from "./Roulette";
import { AnimatePresence } from "framer-motion";
import Home from "./Home";
import "./TransitionRoute.css";

function MyApp() {
  return (
    <div className="App">
      <Router>
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/home" element={<Home />} />
            <Route path="/roulette" element={<Roulette />} />
          </Routes>
        </AnimatePresence>
      </Router>
    </div>
  );
}

export default MyApp;
